import "./TrainingStage.css";
import { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import TrainingTask from "./TrainingTask";
import Space from "./Space";
import confetti from "canvas-confetti";

class TrainingStage extends Component {
  constructor(props) {
    super(props);
    this.onTaskInteract = this.onTaskInteract.bind(this);

    this.state = {
      tasks: _.map(this.props.stage.tasks, (task) => ({
        complete: [],
        ...task,
      })),
      confetti: false,
    };
  }

  componentDidUpdate() {
    if (!this.finished() || this.state.confetti) return;

    const options = {};
    const iteration = () => {
      // HACK: There's probably a much smarter or idiomatic way to achieve this
      if (!this.updater.isMounted(this)) return;

      confetti({ ...options, angle: 120, origin: { x: 0.25, y: 0.75 } });
      confetti({ ...options, angle: 90, origin: { x: 0.5, y: 0.75 } });
      confetti({ ...options, angle: 60, origin: { x: 0.75, y: 0.75 } }).then(
        iteration
      );
    };

    // Start an infinite loop of confetti
    iteration();
    this.setState({ confetti: true });

    // Also start confetti in Gridd Space
    // HACK: Sending the message directly like this isn't ideal
    if (window.grdd) window.grdd.sendGriddMessage("stagecomplete", {});

    // Play a celebration sound
    const completeAudio = new Audio("/complete.wav");
    completeAudio.addEventListener("canplaythrough", () => {
      completeAudio.play();
    });
  }

  componentWillUnmount() {
    confetti.reset();
  }

  objectUsed(name) {
    return _.some(this.state.tasks, (task) => task.complete.includes(name));
  }

  taskComplete(task) {
    return task.complete.length >= task.required;
  }

  finished() {
    return _.every(this.state.tasks, this.taskComplete);
  }

  onTaskInteract({ name }) {
    const objectName = name.split("_")[0];

    const tasks = [...this.state.tasks];
    const task = _.find(tasks, { objectName });

    if (!task.complete.includes(name)) {
      task.complete.push(name);
      this.setState({ tasks });

      // If this didn't complete the training stage, play
      // the interact success audio clip.
      if (!_.every(tasks, this.taskComplete)) {
        const interactAudio = new Audio("/interact.wav");
        interactAudio.addEventListener("canplaythrough", () => {
          interactAudio.volume = 0.3;
          interactAudio.play();
        });
      }
    }
  }

  render() {
    const tasks = _.map(this.state.tasks, (task, key) => (
      <li key={key}>
        <TrainingTask
          task={{ ...task, isComplete: () => this.taskComplete(task) }}
        />
      </li>
    ));

    const objects = _.flatMap(this.state.tasks, (task) => {
      return _.map(_.range(1, task.count + 1), (n) => {
        // This only works for 0 <= n < 10, but even in
        // 2021 JS doesn't have decent string formatting
        // capabilities, so it will have to do.
        const objectName = task.objectName.concat(`_00${n}`);
        return {
          name: objectName,
          interactive: !this.taskComplete(task) && !this.objectUsed(objectName),
        };
      });
    });

    let restartButton = null;
    if (this.finished()) {
      restartButton = (
        <Link className="training-stage--restart" to="/">
          <div className="training-stage--restart-link">Complete Training</div>
        </Link>
      );
    }

    return (
      <div className="training-stage">
        <header className="stage-header">
          <h2>{this.props.stage.name}</h2>
          <p>{this.props.stage.description}</p>
        </header>
        <h2>Tasks</h2>
        <ul>{tasks}</ul>
        {restartButton}
        <Space
          space={this.props.stage.space}
          objects={objects}
          onObjectClick={this.onTaskInteract}
        />
      </div>
    );
  }
}

export default TrainingStage;
