import "./Home.css";
import { Link } from "react-router-dom";

const Home = (props) => {
  return (
    <div className="home">
      <h1>VR Training</h1>
      <section className="home-intro">
        <h2>Welcome!</h2>
        <p>
          You've entered the Agema Work Training application for{" "}
          <a href="https://gridd.xyz/">Gridd Space</a>.
        </p>
        <p>
          In this application you can train for a variety of service roles, in
          virtual recreations of the exact job sites that you will be working
          in. Click below to select a training scenario and start today!
        </p>
        <Link className="home--start" to="trainings">
          <div className="home--start-link">Start Training</div>
        </Link>
      </section>
    </div>
  );
};

export default Home;
