import "./App.css";
import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import GriddContext from "./contexts/GriddContext";
import Home from "./components/Home";
import TrainingSelection from "./components/TrainingSelection";
import Training from "./components/Training";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // Store the Gridd Space API object so that
    // we can provide it child components.
    if (window.grdd) {
      this.setState({ grdd: window.grdd });
    } else {
      // Wait until Gridd Space is ready
      window.addEventListener("spaceready", () => {
        this.setState({ grdd: window.grdd });
      });
    }
  }

  render() {
    return (
      <GriddContext.Provider value={this.state.grdd}>
        <div className="app">
          <header className="app-header">
            <img
              src="/Agema-WhiteLogo.png"
              className="agema-logo"
              alt="Agema Work Logo"
            />
          </header>
          <main className="app-main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/trainings" element={<TrainingSelection />}>
                <Route path=":trainingId" element={<Training />} />
              </Route>
              <Route
                path="*"
                element={
                  <p>
                    Uh oh! That page was not found.{" "}
                    <Link to="/">Click Here to Return</Link>
                  </p>
                }
              />
            </Routes>
          </main>
        </div>
      </GriddContext.Provider>
    );
  }
}

export default App;
