import "./TrainingTask.css";
import _ from "lodash";

const TrainingTask = (props) => {
  const classNames = [
    "training-task",
    props.task.isComplete()
      ? "training-task--complete"
      : "training-task--incomplete",
  ];

  let desc = props.task.description;
  if (props.task.required > 1) {
    const req = props.task.required;
    const comp = _.min([req, props.task.complete.length]);
    desc = desc.concat(` (${comp}/${req})`);
  }

  return <div className={classNames.join(" ")}>{desc}</div>;
};

export default TrainingTask;
