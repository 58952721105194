import { useParams } from "react-router-dom";
import TrainingStage from "./TrainingStage";
import database from "../database.json";

const Training = () => {
  const params = useParams();

  return (
    <TrainingStage
      stage={
        database.trainings.find((t) => t.id === params.trainingId).stages[0]
      }
    />
  );
};

export default Training;
