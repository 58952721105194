import "./TrainingSelection.css";
import _ from "lodash";
import { Link, Outlet, useParams } from "react-router-dom";
import database from "../database.json";

const TrainingSelection = () => {
  const trainings = _.map(database.trainings, (training, key) => {
    const classNames = [
      "trainings--training",
      training.enabled
        ? "trainings--training-enabled"
        : "trainings--training-disabled",
    ];

    const content = training.enabled ? (
      <Link to={`/trainings/${training.id}`} key={training.id}>
        <div className="trainings--training-link">{training.name}</div>
      </Link>
    ) : (
      <div className="trainings--training-link">{training.name}</div>
    );

    return (
      <li key={key} className={classNames.join(" ")}>
        {content}
      </li>
    );
  });

  let content = (
    <div className="trainings">
      <h2>Training Scenarios</h2>
      <ul className="trainings--list">{trainings}</ul>
    </div>
  );

  // If we've selected a training, don't show the list
  const { trainingId } = useParams();
  if (trainingId !== undefined) {
    content = <Outlet />;
  }

  return content;
};

export default TrainingSelection;
