import "./Space.css";
import { Component } from "react";
import _ from "lodash";
import GriddContext from "../contexts/GriddContext";

const mimeType = (modelType) => {
  switch (modelType) {
    case "json":
      return "model/gltf+json";
    default:
    case "binary":
      return "model/gltf-binary";
  }
};

class Space extends Component {
  static contextType = GriddContext;

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = { configured: false };
  }

  componentDidMount() {
    const grdd = this.context;
    if (!grdd) return;

    this.updateSpace(grdd);
  }

  componentDidUpdate() {
    const grdd = this.context;
    if (!grdd) return;

    this.updateSpace(grdd);
  }

  componentWillUnmount() {
    const grdd = this.context;
    if (!grdd) return;

    // Clean up our interaction event listeners
    if (this.state.configured) {
      grdd.interaction.removeEventListener("click", this.onClick);
    }

    // Don't need to change object interactivity state because
    // the Space is going to be unloaded when this component
    // gets unmounted.
  }

  updateSpace(grdd) {
    // Make sure we're setup for hearing about interaction events
    if (!this.state.configured) {
      grdd.interaction.addEventListener("click", this.onClick);
      this.setState({ configured: true });
    }

    // Set the interactivity state for the given objects in the Space
    this.props.objects.forEach((o) => {
      grdd.sendGriddMessage("interactive", {
        objectName: o.name,
        enabled: o.interactive,
      });
    });
  }

  onClick({ detail }) {
    const obj = _.find(this.props.objects, { name: detail });
    if (!obj || !obj.interactive) return;

    if (this.props.onObjectClick) this.props.onObjectClick(obj);
  }

  render() {
    const model = this.props.space.model;
    return (
      <div className="space">
        <object
          className="space-model"
          aria-label={this.props.space.description}
          data={model.uri}
          type={mimeType(model.type)}
        />
      </div>
    );
  }
}

export default Space;
